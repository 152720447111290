<main>
  @if (showOtp && !hideOnBind && isMobileOtp) {
  <div #inputElement class="otp" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <mat-card appearance="outlined" class="ib-otp mar-t-50">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <mat-icon svgIcon="ib_logo" class="ib_logo"></mat-icon>
        <h1 class="login-title ">Welcome to Metro Bank Online Banking</h1>
      </div>
      <div class="login-seperator"></div>
      <div class="ib-otp-form">
        <form #otpForm="ngForm" name="loginForm" novalidate="" autocomplete="off">
          <div class="mar-t-10 mar-b-40">
            <div class="">
              <div class="">
                <div class="">
                  <div class="">
                    <h2 class="margin-0"><label class="user-label">We have sent you a text</label></h2>
                    <div class="">
                      <div class="full-width">
                        <div class="seeds-dialog">
                          We’ve texted a code to your mobile number ending in {{mobile}}. When you get it, please enter
                          the code below:
                        </div>
                      </div>
                    </div>
                    <div class="mar-t-10 full-width">
                      <div class="">
                        <h2 class="margin-0"><label class="user-label">Code</label></h2>
                        <input
                          [class]="(otpForm.submitted && passseed0.invalid)?'ib-otp-value__err ib-otp-value': 'ib-otp-value'"
                          #passseed0="ngModel" type="password" name="password0" size="8" [(ngModel)]="otpValue"
                          minlength="8" maxlength="8" aria-label="Code" required>
                      </div>
                    </div>
                    <div class="mar-t-10">
                      <span class="mar-t-10 mar-r-10" [class]="counter >= 4?'disable-links':''">
                        <button mat-flat-button class="ib-link-btn button-link" (click)="resendOtp()"
                          aria-label="Re-send Code" type="button" tabindex="0">Re-send code</button>
                      </span>
                    </div>
                    @if (otpForm.submitted && (passseed0.invalid)) {
                    <p class="err-msg" role="alert">One Time Password is required</p>
                    }
                  </div>
                </div>
                <div class="mar-t-40">
                  <h2 class="margin-0"><label class="user-label">Can we help?</label></h2>
                </div>
                <mat-expansion-panel class="otp-expansion-panel mar-t-10" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="login-info-title">This isn’t the right mobile number</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    If these are not the last four digits of your mobile number, please call us on <br>
                    <a href="tel:03450808500" class="ib-link-row">034508 08 500</a> to update your contact details.
                  </mat-panel-description>
                </mat-expansion-panel>
                <mat-expansion-panel class="otp-expansion-panel mar-t-10" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="login-info-title">I haven’t received a text</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-panel-description>
                    If you have not received a text message, please call us on <a href="tel:03450808500"
                      class="ib-link-row">0345 08 08 500</a>
                    and we will help you. </mat-panel-description>
                </mat-expansion-panel>
                @if (isLandlineFeature) {
                <mat-expansion-panel class="otp-expansion-panel mar-t-10" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="login-info-title">Please call my landline and read me the code
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  @if (landline) {
                  <mat-panel-description>
                    We will tell you your code on a call to your landline number ending {{landline}} .<br>
                    <span> <button mat-flat-button class="ib-link-btn button-link" tabindex="0" id="requestCall"
                        (click)="requestLandlineOtp()">Request call</button></span>
                    <br>Not your landline number? Please call us on <br><a href="tel:03450808500"
                      class="ib-link-row">0345 08 08 500</a>
                  </mat-panel-description>
                  }
                  @if (!landline) {
                  <mat-panel-description>
                    We don’t have a landline number linked to your account. Please call us on <a href="tel:03450808500"
                      class="ib-link-row">0345
                      08 08 500</a> to update your contact details.
                  </mat-panel-description>
                  }
                </mat-expansion-panel>
                }
              </div>
              <div class="mar-t-10">
                <div class="">
                  <div class="ib-ibid-button" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                    <div class="">
                      <div class="mar-t-10 mar-b-40">
                        <span aria-busy="true">
                          @if (transmitLoader) {
                          <img class="btn-loader" alt="loading" [src]="env.loader">
                          }
                          <button mat-flat-button color="primary" class="continue ibid-btn" type="submit"
                            (click)="submitOtp()" aria-label="Submit OTP">Confirm</button></span>
                      </div>
                    </div>
                    @if (false) {
                    <div class="">
                      <button class="btn" (click)="cancelLogin()" aria-label="Cancel log in click here"
                        class="ib-link-btn" target="_blank" title="Cancel log in">Cancel log in</button>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
  }

  @if (isLandline) {
  <app-landline-otp (showMobileOtp)="showMobileOtp($event)" [isLandline]="isLandline" [showOtp]="showOtp"
    [hideOnBind]="hideOnBind" [landline]="landline" (submitLandlineOtp)="submitLandlineOtp($event)"
    (callAgain)="callAgain($event)" (reqMobileOtp)="reqMobileOtp($event)">
  </app-landline-otp>
  }
</main>