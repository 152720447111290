@if (betaConfig) {
<div class="">
  <div class="content-container container">
    <div class="content-header">
      Access the Beta site or continue to the Live site
    </div>
    <br fxHide.xs=false;>
    <br fxHide.xs=false;>
    <br>
    <div class="btn-container" class="mar-t-50" fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px"
      fxLayoutAlign.xs="space-between center">
      <button class="primary-btn btn" (click)="submitChoice('beta')"> Beta</button>
      <button class="secondary-btn btn" (click)="submitChoice('phoenix')">Live</button>
    </div>
  </div>
  <br fxHide.xs=false;>
  <br fxHide.xs=false;>
  <br><br>
</div>
}
@else {
<div class="">
  <div class="content-container container">
    <div class="content-header">
      We’re excited to show you our new Online Banking
    </div>
    <div class="layout-separator">
      <div class="separator">
      </div>
    </div>
    <main class="communication-block">
      <div class="communication-block-content">
        <p>Our new improved Online Banking is simple and easy to use – why not take a look now?<br>
          <br>Some features aren’t available yet, so you can switch
          between the existing and new versions by logging out. When you log back in,
          you’ll come here again and can choose which one to go to.
        </p>
      </div>
    </main>
    <br fxHide.xs=false;>
    <br>
    <div class="btn-container" class="mar-t-50" fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="20px"
      fxLayoutAlign.xs="space-between center">
      <button class="primary-btn btn" (click)="submitChoice('phoenix')">Continue to new Online
        Banking</button>
      <button class="secondary-btn btn" (click)="submitChoice('edge')">Continue to existing
        Online Banking</button>
    </div>
  </div>
  <br fxHide.xs=false;>
  <br fxHide.xs=false;>
  <br><br>
</div>
}