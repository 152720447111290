@if (displayModal) {
<div>
  <div class="modal-dialog__overlay" role="dialog" aria-labelledby="msgTemplate">
    <div class="modal-dialog__main-body">
      <div class="modal-header">
        <span class="ui-dialog-title" id="ui-dialog-title-FMT_D7B8840718C6977072249" [innerHTML]="msgTemplate"></span>
      </div>
      <div class="modal-body">
        <div [innerHTML]="descriptionTemplate"></div>
      </div>
      <div class="modal-footer">
        @switch (errorType) {
        @case ('TIMEOUT')
        {
        <span>
          <button mat-flat-button class="ibid-btn" type="submit" (click)="reLogin()" aria-label="Log in Again">Log in
            Again</button>
        </span>
        }
        @case ('BROWSER_BINDING' || 'BROWSER_BINDING_WARN')
        {
        <span>
          <a class="link-dont" (click)="rememberBrowser(false)" aria-label="dont save as a Trusted Device">Dont Save</a>
          <button class="ibid-btn" type="submit" (click)="rememberBrowser(true)"
            aria-label="Save as a Trusted Device">Save as a Trusted Browser</button>
        </span>
        }
        }
      </div>
    </div>
  </div>
</div>
}