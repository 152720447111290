<!--The content below is only a placeholder and can be replaced.-->
<div>
  <div [class]="!choice? 'ib-body-bg':''">
    <ib-header></ib-header>
    @if (!choice) {
    <div><app-login></app-login></div>
    }
    @else {
    <div><app-choice-screen></app-choice-screen></div>
    }
    <ib-footer></ib-footer>
    <err-modal></err-modal>
  </div>
</div>