@if (errorDisplay) {
<div #errorElement>
    <div class="mar-t-10">
        <div class="error-band">
            @if (!display) {
            <div class="error-ib-banner" role="dialog" attr.aria-labelledby={{errorHeading}}>
                <mat-card appearance="outlined" class="ib-banner" fxLayout="column" fxLayoutAlign="space-between"
                    fxLayoutGap="10px" layout-wrap>
                    <mat-card-content>
                        <span class="banner-content">
                            <mat-card-title class="card-container-right"> <mat-icon svgIcon="ib_x" aria-label="Close"
                                    class="ib-close-icon" (click)="this.hideError()"></mat-icon></mat-card-title>
                            <mat-icon svgIcon="ib_alert_err" class="ib-err-icon"></mat-icon>&nbsp;
                            <span [innerHTML]="errorTemplate"></span>
                            @if (!lastAttempt && !generalErr) {
                            <mat-icon svgIcon="ib_new_tab" class="ib-new-tab-icon"></mat-icon>
                            }
                        </span>
                    </mat-card-content>
                </mat-card>
            </div>
            }
        </div>
    </div>
</div>
}